import Swiper from 'swiper';
import { Autoplay, Pagination } from 'swiper/modules';
import videojs from 'video.js';
import '../../../../../../../node_modules/videojs-youtube/dist/Youtube.min.js';

const heroSliderClass = '.js-hero-slider';
const heroSlideClass = 'js-hero-slide';
const heroSliderTextClass = '.js-hero-slide-container';
const heroSlideImgClass = '.hero-slider__bg';

const heroSlideImgEl = document.querySelectorAll(heroSlideImgClass);
if (heroSlideImgEl) heroSlideImgEl.forEach(item => item.style.display = 'block');

// Swiper init
const heroSlider = new Swiper(heroSliderClass, {
    modules: [Pagination, Autoplay],
    slidesPerView: 1,
    loop: true,
    lazy: true,
    preloadImages: false,
    autoplay: {
        delay: 6000,
    },
    pagination: {
        el: '.js-hero-slider-pagination',
        clickable: true,
        bulletClass: 'hero-slider__pagination-item',
        bulletActiveClass: 'is-active',
    },
    on: {
        slideChange: (swiper) => {
            if (!isMobileDevice()) {
                const { slides, activeIndex } = swiper;
                updateSlideAnimation(slides, activeIndex);
            }
        },
    },
});

const heroSliderEl = document.querySelectorAll(heroSliderClass);
if (heroSliderEl) {
    heroSliderEl.forEach(slider => {
        const videos = slider.querySelectorAll('[data-video-js]');

        if (videos) {
            videos.forEach(item => {
                videojs(item);
            });
        }

        let slides = slider.getElementsByClassName(heroSlideClass);
        slides = Array.from(slides);

        updateSlideAnimation(slides, 0);
    });
}

function isMobileDevice() {
    return (
        window.innerWidth <= 768 ||
        /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    );
}

function updateSlideAnimation(slides, index) {
    slides.forEach(slide => {
        let container = slide.querySelector(heroSliderTextClass);
        if (container) {
            container.classList.remove('aos-animate');
        }
    })
    let current = slides[index].querySelector(heroSliderTextClass);
    if (current) {
        current.classList.add('aos-animate');
    }
}